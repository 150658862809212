import React, {useEffect, useState} from 'react';
import LogoutBar from "../../globalCompo/LogoutBar";
import {Button, Table} from "react-bootstrap";
import {makeStyles} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import productApi from "../../api/productApi";
import EditIcon from "@material-ui/icons/Edit";
import LinkIcon from "@material-ui/icons/Link";
import ProductMenu from "./ProductMenu";
import ProductOverrideFeaturesModal from "./ProductOverrideFeaturesModal";

export default function ProductOverrideFeatures() {
    const defaultSource = 'seerkle-bo';
    const classes = useStyle();
    const history = useHistory();
    const [overrideProducts, setOverridesProducts] = useState([]);
    const [modal, setModal] = useState({show: false, product: null});

    const getOverriddenProducts = () => productApi.getOverriddenProducts(defaultSource).then(res => setOverridesProducts(res.data.products ?? []))

    useEffect(async () => {
        await getOverriddenProducts()
    }, [])

    const backtoHome = async () => history.push('/')

    return (
        <>
            <LogoutBar/>
            <Button
                className={classes.button}
                variant="dark"
                onClick={backtoHome}
            >
                Retour
            </Button>
            <ProductMenu current="override"/>
            <div className={classes.topButton}>

                <Button
                    className={`${classes.button} ${classes.floatRight}`}
                    variant="dark"
                    style={{marginBottom: "1rem"}}
                    onClick={() => setModal({show: true})}
                >
                    Surcharger un produit
                </Button>
            </div>
            <div>
                <Table>
                    <thead>
                    <tr>
                        <td>Nom du produit</td>
                        <td>Slug produit</td>
                        <td align="center" valign="center">Caractéristiques modifiées</td>
                        <td></td>
                    </tr>
                    </thead>
                    <tbody>
                    {overrideProducts.map((product, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                    {product.name}
                                </td>
                                <td>
                                    {product.slug}
                                </td>
                                <td align="center" valign="center">
                                    {Object.keys(product.overridden_features).length}
                                </td>
                                <td className={classes.alignRight}>
                                    <EditIcon className={classes.cursorPointer}
                                              onClick={() => setModal({show: true, product: product})}/>
                                    <a href={`https://www.seerkle.com/produits/${product.slug}`} target="_blank"
                                       rel="noreferrer" className={classes.productLink}>
                                        <LinkIcon/>
                                    </a>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
            </div>
            <ProductOverrideFeaturesModal
                show={modal.show}
                onHide={() => {
                    setModal({show: false, product: undefined})
                    getOverriddenProducts()
                }}
                product={modal.product}
            />
        </>
    )
}

const useStyle = makeStyles(() => ({
    floatRight: {
        float: "right"
    },
    button: {
        height: "3rem",
        boxSizing: "border-box",
    },
    alignRight: {
        textAlign: "right",
    },
    cursorPointer: {
        cursor: "pointer",
    },
    productLink: {
        marginLeft: '.5rem',
    },
}));
