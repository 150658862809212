/* eslint-disable react/prop-types */
import React from "react";
import { TextField, makeStyles } from "@material-ui/core";

export default function Feature({ feature, onFeatureChange, isModified }) {
    const classes = useStyles();

    const handleChange = (event) => {
        onFeatureChange({
            ...feature,
            value: event.target.value
        });
    };

    return (
        <div className={classes.div}>
            <TextField
                label={feature.name}
                value={feature.value || ""}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                size="small"
                InputLabelProps={{
                    className: isModified ? classes.modifiedLabel : undefined
                }}
            />
        </div>
    );
}

const useStyles = makeStyles(() => ({
    modifiedLabel: {
        color: "blue"
    },
    div:{
        marginBottom: "1rem"
    }
}));