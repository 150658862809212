/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {Button, Form, Modal, Row} from "react-bootstrap";
import SearchComponent from "./Component/SearchComponent";
import productApi from "../../api/productApi";
import toast from "react-hot-toast";
import {makeStyles} from "@material-ui/core";

export default function ProductDuplicatesModal({show, onHide, product}) {
    const classes = useStyles();
    const [formData, setFormData] = useState({
        product: '',
        product_uuid: null,
        duplicates: [],
        duplicate_uuids: []
    });

    const handleProductSelect = (product) => {
        setFormData(prev => ({
            ...prev,
            product: product.slug,
            product_uuid: product.uuid
        }));
    };

    const handleDuplicatesSelect = (product, index) => {
        const isDuplicate = formData.duplicates.some(
            dup => dup && dup.uuid === product.uuid
        );

        if (isDuplicate) {
            toast.error('Ce produit est déjà dans la liste des doublons');
            return;
        }

        setFormData(prev => {
            const newDuplicates = [...prev.duplicates];
            const newDuplicatesUuids = [...prev.duplicate_uuids]
            newDuplicates[index] = product.slug
            newDuplicatesUuids[index] = product.uuid
            return {
                ...prev,
                duplicates: newDuplicates,
                duplicate_uuids: newDuplicatesUuids
            };
        });
    };

    const handleRemove = (index) => {
        const newDuplicates = [...formData.duplicates];
        const newDuplicatesUuids = [...formData.duplicate_uuids];
        newDuplicates.splice(index, 1);
        newDuplicatesUuids.splice(index, 1);
        setFormData({...formData, duplicates: newDuplicates, duplicate_uuids: newDuplicatesUuids});
    }
    const handleHide = () => {
        resetForm()
        onHide()
    }

    const resetForm = () => {
        setFormData({
            product: '',
            product_uuid: null,
            duplicates: [],
            duplicate_uuids: []
        });
    };


    const addNewDuplicate = () => {
        setFormData(prev => ({
            ...prev,
            duplicates: [...prev.duplicates, null]
        }));
    };

    const handleSave = async () => {
        const response = await productApi.saveDuplicates(formData);
        if (response.status === 200) {
            toast.success('Doublons enregistrés')
            onHide();
        }
    }
    useEffect(() => {
        if (product) {
            setFormData({
                product: product.slug,
                product_uuid: product.uuid,
                duplicates: product.duplicates,
                duplicate_uuids: product.duplicates.map((item) => item.uuid)
            });
        } else {
            resetForm();
        }
    }, [product]);

    useEffect(() => {
        if (formData.duplicates.length === 0) {
            addNewDuplicate();
        }
    }, [formData])

    return (
        <Modal
            show={show}
            onHide={handleHide}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Gestions des produits
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group as={Row} className={`mb-3 ${classes.formGroup}`} controlId="formName">
                    <div className={classes.productRow}>
                        <SearchComponent
                            value={formData.product}
                            onChange={handleProductSelect}
                            title={"URL canonique"}
                        />
                    </div>

                    {formData.duplicates.map((duplicate, index) => (
                        <div className={classes.productRow} key={index}>
                            <SearchComponent
                                value={duplicate?.slug || ''}
                                onChange={(product) => handleDuplicatesSelect(product, index)}
                                title={`URL doublon ${index + 1}`}
                            />
                            <Button variant={"link"} onClick={() => handleRemove(index)}>
                                ❌
                            </Button>
                        </div>
                    ))}
                </Form.Group>
                <div className={classes.actionButtons}>
                    <Button onClick={addNewDuplicate} className={classes.addButton}>
                        Ajouter un doublon
                    </Button>
                    <Button onClick={handleSave}>
                        Sauvegarder
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

const useStyles = makeStyles(() => ({
    formGroup: {
        display: "flex",
        flexDirection: "column",
    },
    productRow: {
        display: "flex",
        alignItems: "center",
    },
    actionButtons: {
        float: 'right',
    },
    addButton: {
        marginRight: '10px',
    }
}));
