import api from './api';

const searchByName = async (name, page, withoutReview = null) => await api.post('/products/search-by-name', {
    name,
    page,
    withoutReview
});

const searchBySlug = async (slug, page) => await api.post('/products/search-by-slug', {
    slug,
    page,
});
const deactivate = async uuid => await api.post(`/products/${uuid}/deactivate`);
const reactivate = async uuid => await api.post(`/products/${uuid}/reactivate`);
const getDeactivatedProducts = async () => await api.get('/products/deactivated');

const saveReview = async data => await api.post(`/products/${data.products_uuid}/review`, {data})

const getReviewedProducts = async () => await api.get(`/products/with-review`)
const removeReview = async product => await api.delete(`/products/${product.uuid}/review`)
const getDuplicates = async () => await api.get(`/products/duplicates`)
const getOverriddenProducts = async source => await api.get(`/products/overridden/${source}`)
const overrideProduct = async (product,source) => await api.post(`/products/${product.uuid}/override/${source}`, {product})
const saveDuplicates = async data => await api.put(`/products/${data.product_uuid}/duplicates`, {data})

export default {
    searchByName,
    deactivate,
    reactivate,
    getDeactivatedProducts,
    saveReview,
    getReviewedProducts,
    removeReview,
    saveDuplicates,
    getDuplicates,
    searchBySlug,
    getOverriddenProducts,
    overrideProduct
}
