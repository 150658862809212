/* eslint-disable react/prop-types */
import {isString} from "lodash-es";
import React, {useEffect, useState} from "react";
import {Col, Form, Modal, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import featuresApi from "../../../api/featuresApi";

export default function UpdateFeatureModal ({show, feature, category, onHide, callback}) {
  const MAX_MASTER_FEATURES = 6
  const base = {
    name: '',
    url: '',
    description: '',
    iceCatId: '',
    order: null,
    label: '',
    values: [],
    masterExisting: false,
  };
  const [possibleValues, setPossibleValues] = useState([])
  const [data, setData] = useState({...base})

  useEffect(() => {
    setData({...base, ...feature})
    if (feature) {
      featuresApi.listPossibleValues(feature.uuid).then(res => setPossibleValues(res.data))
    }
  }, [show, feature]);

  const onPositionChange = e => setData({...data, order: e.target.value});
  const onNameChange = e => setData({...data, name: e.target.value});
  const onUrlChange = e => setData({...data, url: e.target.value});
  const onValueUrlChange = (value, e) => {
    const values = data.values
    const index = values.findIndex(v => v.value === value.value)
    if (index >= 0) {
      values[index].url = e.target.value
    }
    setData({...data, values})
  }
  const onDescriptionChange = e => setData({...data, description: e.target.value});
  const isMasterDisabled = () => !data.iceCatId?.trim()
  const isMasterPositionDisabled = () => !data.label?.trim()

  const checkMasterAmount = () => {
    const {label, masterExisting} = data
    return masterExisting || (isString(label) && !label.trim()) || category.master_features.length <= MAX_MASTER_FEATURES
  }

  const checkMasterPosition = () => {
    const {order} = data;
    return isMasterDisabled() || order === null || (isString(order) && order.trim() === '') || (parseInt(order) > 0 && parseInt(order) <= MAX_MASTER_FEATURES);
  }

  const checkRequiredFields = () => data.name?.trim() && checkMasterAmount() && checkMasterPosition()

  const handleSave = () => {
    if (!checkRequiredFields()) {
      return false;
    }
    featuresApi.save({...data, uuid: feature.uuid, categoryUuid: category.uuid}).then(callback)
    onHide();
  };

  const onLabelChange = e => {
    const {value} = e.target;
    const masterData = {};
    if (isString(value) && value.trim() === '') {
      masterData.order = '';
    }
    setData({...data, ...masterData, label: value})
  };

  const changeValue = (e, index) => setData(prevData => ({
    ...prevData, values: [
      ...prevData.values.slice(0, index),
      ...[possibleValues.find(v => v.value === e.target.value)].filter(v => v !== undefined),
      ...prevData.values.slice(index + 1)
    ]
  }));

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Champ {feature ? "-" : ""} {feature?.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Informations</h5>
        <Form.Group as={Row} controlId="formName">
          <Form.Label column sm="2">
            Nom
          </Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              placeholder="Nom du champ"
              value={data.name || ""}
              onChange={onNameChange}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm="2">
            Identifiant
          </Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              placeholder="Identifiant iceCat du champ ( id )"
              value={data.iceCatId || ""}
              readOnly={true}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm="2">
            URL
          </Form.Label>
          <Col sm="10">
            <Form.Control
              type="url"
              placeholder="URL"
              value={data.url || ""}
              onChange={onUrlChange}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm="2">
            Description
          </Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              placeholder="Description"
              value={data.description || ""}
              onChange={onDescriptionChange}
            />
          </Col>
        </Form.Group>

        <h5
          style={{
            borderTop: "1px solid #ced4da",
            paddingTop: 16,
            paddingBottom: 8,
          }}
        >
          Master caractéristiques
        </h5>

        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Libellé
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              placeholder="libellé du master caract."
              onChange={onLabelChange}
              value={data.label}
              disabled={isMasterDisabled()}
            />
            <Form.Control.Feedback type="invalid" style={{display: checkMasterAmount() ? 'none' : 'block'}}>
              {MAX_MASTER_FEATURES} master-caract. existent déjà, merci d&quot;en supprimer au moins une
            </Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Position du master caract.
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="number"
              placeholder="position du master caract."
              value={data.order}
              onChange={onPositionChange}
              disabled={isMasterDisabled() || isMasterPositionDisabled() || !checkMasterAmount()}
            />
            <Form.Control.Feedback type="invalid" style={{display: checkMasterPosition() ? 'none' : 'block'}}> Valeur erronée. </Form.Control.Feedback>
          </Col>
        </Form.Group>

        {feature?.isNumerical || <>
          <h5 style={{borderTop: "1px solid #ced4da", paddingTop: 16, paddingBottom: 8}}>
            Valeurs spécifiques
          </h5>

          {data.values.map((value, index) => (
            <Form.Group as={Row} key={index}>
              <Form.Label column sm="2">Valeur {index + 1}</Form.Label>
              <Col sm="4">
                <select onChange={e => changeValue(e, index)} value={value.value || ''}>
                  <option value=''>--- Choisir ---</option>
                  {possibleValues.filter(v => {
                    const i = data.values.map(v2 => v2.value).indexOf(v.value)
                    return i === -1 || i === index
                  })
                    .sort((a, b) => a.value > b.value ? 1 : a.value < b.value ? -1 : 0)
                    .map(v => (<option key={v.value} value={v.value}>{v.value}</option>))}
                </select>
              </Col>
              <Col sm={6}>
                <Form.Control type="url" placeholder="URL" onChange={e => onValueUrlChange(value, e)} value={value.url || ''}/>
              </Col>
            </Form.Group>
          ))}
          <Form.Group as={Row}>
            <Form.Label column sm="2">Valeur {data.values.length + 1}</Form.Label>
            <Col sm="10">
              <select onChange={e => changeValue(e, data.values.length)} value=''>
                <option value=''>--- Choisir ---</option>
                {possibleValues.filter(v => data.values.map(v2 => v2.value).indexOf(v.value) === -1)
                  .sort((a, b) => a.value > b.value ? 1 : a.value < b.value ? -1 : 0)
                  .map(v => (<option key={v.value} value={v.value}>{v.value}</option>))}
              </select>
            </Col>
          </Form.Group>
        </>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onHide}>
          Annuler
        </Button>
        <Button
          disabled={!checkRequiredFields()}
          variant="dark"
          onClick={() => handleSave()}
        >
          {feature?.uuid ? "Sauvegarder" : "Créer"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
