/* eslint-disable react/prop-types */
import {IconButton, Select, TextField, Button, MenuItem, makeStyles} from "@material-ui/core";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import React, { useEffect, useState } from "react";
import featuresApi from "../../../api/featuresApi";
import Toast from "react-hot-toast";

export default function FeatureForm({ onCancel, featureGroupId, productUuid, onSave }) {
    const [features, setFeatures] = useState([]);
    const [selectedFeature, setSelectedFeature] = useState('');
    const [featureValue, setFeatureValue] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const classes = useStyles();

    useEffect(() => {
        let isMounted = true;

        const fetchFeatures = async () => {
            try {
                setIsLoading(true);
                const response = await featuresApi.listFeaturesByFeatureGroup(featureGroupId, productUuid);
                if (isMounted) {
                    const featureGroup = response.data.feature_groups.find(fg => fg.id === featureGroupId);
                    setFeatures(featureGroup ? featureGroup.features : []);
                }
            } catch (error) {
                Toast.error("Erreur lors de la récupération des features");
            } finally {
                if (isMounted) {
                    setIsLoading(false);
                }
            }
        };

        fetchFeatures();

        return () => {
            isMounted = false;
        };
    }, [featureGroupId, productUuid]);

    const handleFeatureChange = (event) => {
        setSelectedFeature(event.target.value);
    };

    const handleValueChange = (event) => {
        setFeatureValue(event.target.value);
    };

    const handleSave = () => {
        if (selectedFeature && featureValue) {
            const selectedFeatureData = features.find(f => f.uuid === selectedFeature);
            if (selectedFeatureData) {
                const featureToSave = {
                    uuid: selectedFeatureData.uuid,
                    name: selectedFeatureData.name,
                    value: featureValue
                };
                onSave(featureToSave);
                onCancel();
            }
        }
    };

    return (
        <div className={classes.div}>
            <Select
                label="Titre"
                style={{ minWidth: '200px' }}
                variant="outlined"
                value={selectedFeature}
                onChange={handleFeatureChange}
                disabled={isLoading}
            >
                {features.map((feature) => (
                    <MenuItem key={feature.uuid} value={feature.uuid}>
                        {feature.name}
                    </MenuItem>
                ))}
            </Select>
            <TextField
                label="Valeur"
                variant="outlined"
                style={{ flex: 1 }}
                value={featureValue}
                onChange={handleValueChange}
            />
            <IconButton
                size="small"
                onClick={handleSave}
                disabled={!selectedFeature || !featureValue}
            >
                <SaveAltIcon/>
            </IconButton>
            <Button
                size="small"
                onClick={onCancel}
                variant="outlined"
                color="secondary"
            >
                Annuler
            </Button>
        </div>
    )
}

const useStyles = makeStyles(() => ({
    div: {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        marginBottom: '1rem',
        padding: '1rem',
        border: '1px solid #eee',
        borderRadius: '4px'
    },
}));
