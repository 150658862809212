/* eslint-disable react/prop-types */
import React from "react";
import {makeStyles} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {Button} from "react-bootstrap";

export default function ProductMenu ({current}) {
    const classes = useStyle();
    const history = useHistory();

    return (
        <>
            <div className={classes.menu}>
                <Button className={classes.button} variant={current === 'duplicates' ? "dark" : "light"} onClick={() => history.push(`/products/duplicates`)}>
                    💢 Doublons
                </Button>
                <Button className={classes.button} variant={current === "override" ? "dark" : "light"}
                        onClick={() => history.push(`/products/override-features`)}>
                    🖍️ Surcharge caractéristiques
                </Button>
            </div>
        </>
    );
}

const useStyle = makeStyles(() => ({
    button: {
        padding: "8px 12px",
        height: "3rem",
        boxSizing: "border-box",
        borderRadius: ".25rem .25rem 0 0",
    },
    menu: {
        display: "flex",
        flexDirection: "row",
        gap: ".25rem",
        margin: "1rem 0",
        borderBottom: ".25rem solid #343a40",
    }
}));
