/* eslint-disable react/prop-types */
import React, {useEffect, useState, useCallback, memo} from 'react';
import {Button, Form, Modal, Row} from "react-bootstrap";
import SearchComponent from "./Component/SearchComponent";
import {makeStyles} from "@material-ui/core";
import FeaturesListComponent from "./Component/FeaturesListComponent";
import productApi from "../../api/productApi";
import toast from "react-hot-toast";

const ProductOverrideFeaturesModal = ({show, onHide, product}) => {
    const classes = useStyles();
    const [formData, setFormData] = useState({
        product: null,
        freeze_product: null,
        product_uuid: null,
    });

    const handleProductSelect = useCallback((selectedProduct) => {
        setFormData(prev => ({
            ...prev,
            product: selectedProduct,
            freeze_product: JSON.parse(JSON.stringify(selectedProduct)),
            product_uuid: selectedProduct.uuid
        }));
    }, []);

    const getChanges = (oldData, newData) => {
        const changes = [];

        newData.forEach((newSection, sectionIndex) => {
            const oldSection = oldData[sectionIndex];
            const sectionChanges = {
                name: newSection.name,
                features: []
            };

            newSection.features.forEach((newFeature) => {
                const oldFeature = oldSection.features.find(f => f.uuid === newFeature.uuid);

                if (!oldFeature) {
                    sectionChanges.features.push({...newFeature});
                } else if (newFeature.value !== oldFeature.value) {
                    sectionChanges.features.push({...newFeature});
                }
            });

            oldSection.features.forEach((oldFeature) => {
                const newFeature = newSection.features.find(f => f.uuid === oldFeature.uuid);

                if (!newFeature) {
                    sectionChanges.features.push({...oldFeature});
                }
            });

            if (sectionChanges.features.length > 0) {
                changes.push(sectionChanges);
            }
        });
        return changes;
    }

    const handleProductUpdate = useCallback((updatedProduct) => {
        setFormData(prev => ({
            ...prev,
            product: updatedProduct
        }));
    }, []);

    const handleHide = useCallback(() => {
        resetForm();
        onHide();
    }, [onHide]);

    const resetForm = useCallback(() => {
        setFormData({
            product: null,
            freeze_product: null,
            product_uuid: null,
        });
    }, []);

    const handleSave = useCallback(async () => {
        try {
            formData.product.feature_groups = getChanges(formData.freeze_product.feature_groups, formData.product.feature_groups)
            console.log(formData.product.feature_groups)
            const response = await productApi.overrideProduct(formData.product, 'seerkle-bo');
            if (response.status === 200) {
                toast.success('Produit enregistré');
                onHide();
            }
        } catch (error) {
            console.error("Error saving product:", error);
        }
    }, [formData.product, formData.freeze_product?.feature_groups, onHide]);

    useEffect(() => {
        if (product) {
            setFormData({
                product: product,
                freeze_product: JSON.parse(JSON.stringify(product)),
                product_uuid: product.uuid,
            });
        } else {
            resetForm();
        }
    }, [product, resetForm]);

    return (
        <Modal
            show={show}
            onHide={handleHide}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Surcharge produit
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group as={Row} className={`mb-3 ${classes.formGroup}`} controlId="formName">
                    <div className={classes.productRow}>
                        <SearchComponent
                            value={formData?.product?.slug ?? ''}
                            onChange={handleProductSelect}
                            title={"Produit"}
                        />
                    </div>
                    {formData.product?.feature_groups ?
                        <FeaturesListComponent
                            product={formData.product}
                            onProductUpdate={handleProductUpdate}
                        /> :
                        ''
                    }
                </Form.Group>
                <div className={classes.actionButtons}>
                    <Button onClick={handleSave}>
                        Sauvegarder
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

const useStyles = makeStyles(() => ({
    formGroup: {
        display: "flex",
        flexDirection: "column",
    },
    productRow: {
        display: "flex",
        alignItems: "center",
    },
    actionButtons: {
        float: 'right',
    },
    addButton: {
        marginRight: '10px',
    }
}));

export default memo(ProductOverrideFeaturesModal);
