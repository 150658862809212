/* eslint-disable react/prop-types */
import React, {useCallback, useState, useEffect} from 'react';
import {Form, Col, ListGroup} from 'react-bootstrap';
import {debounce} from "lodash";
import productApi from "../../../api/productApi";

const DEBOUNCE_DELAY = 700;

export default function SearchComponent({onChange, title, value}) {
    const [searchResults, setSearchResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [error, setError] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [isUserSearching, setIsUserSearching] = useState(false);

    const search = useCallback(async (searchTerm) => {
        if (!searchTerm.trim()) {
            setSearchResults([]);
            return;
        }
        setIsSearching(true);
        setError(null);

        try {
            const response = await productApi.searchBySlug(searchTerm, 1, true);
            setSearchResults(response.data.products);
        } catch (e) {
            setError(e.message);
        } finally {
            setIsSearching(false);
        }
    }, []);

    const debouncedSearch = useCallback(
        debounce((value) => search(value), DEBOUNCE_DELAY),
        [search]
    );

    const handleInputChange = (event) => {
        const newValue = event.target.value;
        setInputValue(newValue);
        setIsUserSearching(true);
        debouncedSearch(newValue);
    };

    const handleClick = useCallback((product) => {
        onChange(product);
        setInputValue(product.slug);
        setSearchResults([]);
        setIsUserSearching(false);
    }, [onChange]);

    useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    return (
        <>
            <Form.Label column={true} sm="2">{title}</Form.Label>
            <Col sm="9">
                <Form.Control
                    type="text"
                    placeholder="Rechercher un produit..."
                    onChange={handleInputChange}
                    value={inputValue}
                    autoComplete="off"
                />
                {isSearching && <div className="text-muted mt-2">Recherche en cours...</div>}
                {error && <div className="text-danger mt-2">{error}</div>}
                {inputValue && !isSearching && searchResults.length === 0 && isUserSearching && (
                    <div className="text-muted mt-2">Aucun résultat trouvé</div>
                )}
                {searchResults.length > 0 && (
                    <ListGroup>
                        {searchResults.map((product) => (
                            <ListGroup.Item
                                key={product.uuid}
                                action
                                onClick={() => handleClick(product)}
                                className="cursor-pointer"
                            >
                                {product.slug}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                )}
            </Col>
        </>
    );
}
