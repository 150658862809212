/* eslint-disable react/prop-types */
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import FeatureGroup from "./FeatureGroups";

export default function FeaturesListComponent({product, onProductUpdate}) {
    const classes = useStyles();

    const handleFeatureGroupChange = (updatedFeatureGroup) => {
        const updatedFeatureGroups = product.feature_groups.map(group =>
            group.name === updatedFeatureGroup.name ? updatedFeatureGroup : group
        );

        onProductUpdate({
            ...product,
            feature_groups: updatedFeatureGroups
        });
    };

    return (
        <div className={classes.root}>
            {product.feature_groups.map((featureGroup, idx) => (
                <FeatureGroup
                    featureGroup={featureGroup}
                    key={idx}
                    product={product}
                    onFeatureGroupChange={handleFeatureGroupChange}
                />
            ))}
        </div>
    );
}

const useStyles = makeStyles(() => ({
    root: {
        padding: '1rem',
    }
}));
