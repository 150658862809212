import React, {useEffect, useState} from 'react';
import LogoutBar from "../../globalCompo/LogoutBar";
import {Button, Table} from "react-bootstrap";
import {makeStyles} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import ProductDuplicatesModal from "./ProductDuplicatesModal";
import productApi from "../../api/productApi";
import EditIcon from "@material-ui/icons/Edit";
import LinkIcon from "@material-ui/icons/Link";
import ProductMenu from "./ProductMenu";

export default function ProductDuplicates () {
  const classes = useStyle();
  const history = useHistory();
  const [duplicatesProducts, setDuplicatesProdutcs] = useState([]);
  const [modal, setModal] = useState({show: false, product: null});

  const getProducts = () => productApi.getDuplicates().then(res => setDuplicatesProdutcs(res.data.products))
  useEffect(async () => {
    await getProducts()
  }, [])

  const backtoHome = async () => history.push('/')

  return (
    <>
      <LogoutBar/>
      <Button
          className={classes.button}
          variant="dark"
          onClick={backtoHome}
      >
        Retour
      </Button>
      <ProductMenu current="duplicates"/>
      <div className={classes.topButton}>
        <Button
          className={`${classes.button} ${classes.floatRight}`}
          variant="dark"
          onClick={() => setModal({show: true})}
        >
          Ajouter un doublon
        </Button>
      </div>
      <div>
        <Table>
          <thead>
          <tr>
            <td>URL de référence</td>
            <td>Nombres de doublons</td>
            <td></td>
          </tr>
          </thead>
          <tbody>
          {duplicatesProducts.map((product, index) => {
            return (
              <tr key={index}>
                <td>
                  {product.slug}
                </td>
                <td>
                  {product?.duplicates.length}
                </td>
                <td className={classes.alignRight}>
                  <EditIcon className={classes.cursorPointer} onClick={() => setModal({show: true, product: product})}/>
                  <a href={`https://www.seerkle.com/produits/${product.slug}`} target="_blank" rel="noreferrer" className={classes.productLink}>
                    <LinkIcon/>
                  </a>
                </td>
              </tr>
            )
          })}
          </tbody>
        </Table>
      </div>
      <ProductDuplicatesModal
        show={modal.show}
        onHide={() => {
          setModal({show: false, product: undefined})
          getProducts()
        }}
        product={modal.product}
      />
    </>
  )
}

const useStyle = makeStyles(() => ({
  topButton: {
    margin: "36px 0",
  },
  floatRight: {
    float: "right"
  },
  button: {
    padding: "8px 12px",
    height: "3rem",
    boxSizing: "border-box",
  },
  alignRight: {
    textAlign: "right",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  productLink: {
    marginLeft: '.5rem',
  },
}));
