/* eslint-disable react/prop-types */
import {Button, Collapse, IconButton, Divider, Typography, makeStyles} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, {useState, useEffect} from "react";
import Feature from "./Feature";
import FeatureForm from "./FeatureForm";

export default function FeatureGroup({featureGroup, onFeatureGroupChange, product}) {
    const [expanded, setExpanded] = useState(false);
    const [featureForms, setFeatureForms] = useState([]);

    const classes = useStyles();

    const hasModifiedFeatures = featureGroup.features.some((feature) =>
        Object.values(product.features_modify || {}).some(
            (modifiedFeature) => modifiedFeature.uuid === feature.uuid
        )
    );

    useEffect(() => {
        if (hasModifiedFeatures) {
            setExpanded(true);
        }
    }, [hasModifiedFeatures]);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleFeatureChange = (updatedFeature) => {
        const updatedFeatures = featureGroup.features.map((feature) =>
            feature.uuid === updatedFeature.uuid ? updatedFeature : feature
        );

        onFeatureGroupChange({
            ...featureGroup,
            features: updatedFeatures
        });
    };

    const handleAddFeatureClick = () => {
        setFeatureForms((prev) => [...prev, {id: Date.now()}]);
    };

    const handleRemoveForm = (formId) => {
        setFeatureForms((prev) => prev.filter((form) => form.id !== formId));
    };

    const handleFeatureAdd = (newFeature) => {
        const updatedFeatures = [...featureGroup.features, newFeature];

        onFeatureGroupChange({
            ...featureGroup,
            features: updatedFeatures
        });
    };

    return (
        <div style={{marginBottom: "1.5rem"}}>
            <div className={classes.div}>
                <Typography
                    variant="subtitle1"
                    className={classes.typographie}
                    style={{
                        color: hasModifiedFeatures ? "blue" : "#333"
                    }}
                >
                    {featureGroup.name}
                </Typography>
                <IconButton
                    size="small"
                    onClick={handleExpandClick}
                    className={classes.iconButton}
                    style={{
                        transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                >
                    <ExpandMoreIcon fontSize="small"/>
                </IconButton>
            </div>
            <Collapse in={expanded}>
                <div style={{paddingLeft: "8px"}}>
                    {featureGroup.features.map((feature, idx) => (
                        <Feature
                            key={idx}
                            feature={feature}
                            onFeatureChange={handleFeatureChange}
                            isModified={Object.values(product.features_modify || {}).some(
                                (modifiedFeature) => modifiedFeature.uuid === feature.uuid
                            )}
                        />
                    ))}
                    {featureForms.map((form) => (
                        <FeatureForm
                            key={form.id}
                            featureGroupId={featureGroup.id}
                            productUuid={product.uuid}
                            onCancel={() => handleRemoveForm(form.id)}
                            onSave={(featureData) => {
                                handleFeatureAdd(featureData);
                                handleRemoveForm(form.id);
                            }}
                        />
                    ))}
                    <div style={{textAlign: "right", marginTop: "0.5rem"}}>
                        <Button
                            size="small"
                            variant="outlined"
                            onClick={handleAddFeatureClick}
                            className={classes.button}
                        >
                            Ajouter une feature
                        </Button>
                    </div>
                </div>
            </Collapse>
            <Divider
                className={classes.divider}
            />
        </div>
    );
}


const useStyles = makeStyles(() => ({
    divider: {
        marginTop: "1rem",
        backgroundColor: "#f0f0f0"
    },
    button: {
        textTransform: "none",
        fontSize: "0.85rem"
    },
    iconButton: {
        transition: "transform 0.3s",
        padding: "4px",
        marginLeft: "4px"
    },
    typographie: {
        fontWeight: 600,
        fontSize: "0.95rem",
        margin: 0,
    },
    div: {
        display: "flex",
        alignItems: "center",
        marginBottom: "0.5rem"
    }
}));
