import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import {Button, OverlayTrigger, Table, Tooltip} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";

import categoriesApi from "../../api/categoriesApi";
import UpdateCategoryModal from "./UpdateCategory";
import LogoutBar from "../../globalCompo/LogoutBar";

export default function Categories () {
  const classes = useStyle();
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const [modal, setModal] = useState({show: false, category: undefined});

  const getCategories = () => categoriesApi.getAll().then(res => setCategories(res.data));
  const addCategory = () => setModal({show: true, category: undefined});
  const closeModal = () => setModal({show: false, category: undefined});

  const editCategory = (e, category) => {
    e.stopPropagation();
    setModal({show: true, category});
  }

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      <LogoutBar/>
      <div className={classes.container}>
        <div className={classes.topButton}>
          <Button
            className={classes.button}
            variant="dark"
            onClick={() => history.push('/product-deactivation')}
          >
            Management BDD
          </Button>
          <Button
              className={`${classes.button} ${classes.ps}`}
              variant="dark"
              onClick={() => history.push('/products/duplicates')}
          >
            Gestions des produits
          </Button>
        </div>
        <div className={classes.flexSpace}>
          <h2 className={classes.title}>Catégories</h2>
          <Button className={classes.button} variant="dark" onClick={addCategory}>
            Ajouter une catégorie
          </Button>
        </div>
        <Table striped bordered hover variant="dark">
          <thead>
          <tr>
            <th>Nom</th>
            <th>Déclinaisons</th>
            <th>Identifiant Icecat</th>
            <th/>
          </tr>
          </thead>
          <tbody>
          {categories.map(category => (
            <tr
              key={category.uuid}
              className={classes.row}
              onClick={() => history.push(`/categories/${category.uuid}`)}
            >
              <td>{category.name}</td>
              <td>{category.is_female ? 'une' : 'un'} {category.name} / des {category.plural}</td>
              <td>{category.catId ? category.catId : ''}</td>
              <td>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="button-tooltip-2">
                      Modifier les informations
                    </Tooltip>
                  }
                >
                  {({ref, ...triggerHandler}) => (
                    <EditIcon
                      {...triggerHandler}
                      ref={ref}
                      onClick={e => editCategory(e, category)}
                    />
                  )}
                </OverlayTrigger>
              </td>
            </tr>
          ))}
          </tbody>
        </Table>

        <UpdateCategoryModal
          show={modal.show}
          onHide={closeModal}
          category={modal.category}
          callback={getCategories}
        />
      </div>
    </>
  );
}

const useStyle = makeStyles(() => ({
  container: {
    margin: "72px 48px",
    boxSizing: "border-box",
  },
  topButton: {
    margin: "36px 0",
  },
  flexSpace: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    padding: "8px 12px",
    height: "3rem",
    boxSizing: "border-box",
  },
  ps: {
    margin: "0 0 0 10px"
  },
  title: {
    marginBottom: 36,
  },
  row: {
    cursor: "pointer",
  },
}));
